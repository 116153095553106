const m23 = [
  {
    title: "Expertos en el sector",
    icon: "icnestrella",
    text:
      "Trabajamos con un equipo de profesionales altamente cualificado, que conoce cada rincón de la capital, se adapta a las necesidades y objetivos de nuestros clientes, y es experto en encontrar oportunidades de inversión seguras y rentables.",
  },
  {
    title: "Gestión integral",
    icon: "icnreloj",
    text:
      "Nos ocupamos de todas las fases del proceso, garantizando el cumplimiento de todos los requisitos legales y técnicos necesarios. Y si lo deseas, también podemos encargarnos de la gestión del inquilino y el mantenimiento del inmueble.",
  },
  {
    title: "Rentabilizamos la inversión",
    icon: "icnflor",
    text:
      "La calidad es el resultado final que buscamos. Por ello, combinamos tecnología, diseño y personas para ofrecer un producto diferencial: viviendas totalmente reformadas que responden a unas guías de estilo y requisitos de habitabilidad.",
  },
]

export default m23
