import React from "react"
import { Box, Columns, MaxWidth, Text, Flex } from "@sqymagma/elements"
import Block from "../components/block"
import { IcnSystem } from "../components/IconSystem/IcnSystem"

import data from "../data/m23"

const Card = ({ cdata }) => {
  return (
    <Box>
      <Flex flexDirection={["column", "row"]} mx="auto">
        <Box>
          <IcnSystem name={cdata.icon} height="48px" fill="brand03" mb="16px" />
        </Box>
        <Box
          ml={["0px", "spc2", "0px"]}
          px={["spc", "0px", "0px", "0px", "spc1"]}
          pb={["32px", "0px"]}
        >
          <Box width={["120px", "120px", "160px", "160px", "200px"]}>
            <Text textStyle="h4" fontWeight="500">
              {cdata.title}
            </Text>
          </Box>
          <Box my="spc2">
            <Text textStyle="p">{cdata.text}</Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}
const Hacemos = () => {
  const listCards = data.map((card, i) => <Card cardID={i + 1} cdata={card} />)
  return (
    <Box bg="bg01">
      <MaxWidth py={["spc4", "spc6", "spc6", "spc8", "spc12"]} textAlign="center">
        <Block>
          <Columns cols={[1, 1, 3]} textAlign="left">
            {listCards}
          </Columns>
        </Block>
      </MaxWidth>
    </Box>
  )
}

export default Hacemos
