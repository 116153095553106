import { Flex as MagmaFlex } from "@sqymagma/elements"
import styled from "styled-components"
import { system } from "styled-system"

// Box ahora soporta la prop-responsive boxwidth la cual establece un ancho para
// el contenedor dependiendo de la primitiva boxwidth.
const Flex = styled(MagmaFlex)`
  ${system({
    size: { properties: ["width", "height"], scale: "spacing" },
  })}
`

export default Flex
