import React from "react"
import { Box, Columns, MaxWidth, Text } from "@sqymagma/elements"
import Flex from "../components/flex"
import Tick from "../components/tick"
import Block from "../components/block"
import styled from "styled-components"

import data from "../data/m24"

const Circle = styled(Flex)`
  border: 4px solid;
  border-color: ${p => p.theme.color.brand03};
`

const Card = ({ cardID, title, text }) => {
  return (
    <Box textAlign="center">
      <Circle
        size={["spc6", "spc6", "spc6", "spc8", "spc12"]}
        mx="auto"
        mt="spc2"
        textAlign="center"
        borderRadius="50%"
        alignItems="center"
        justifyContent="center"
      >
        <Text textStyle="h3" color="brand03">
          {cardID}
        </Text>
      </Circle>
      <Box px={["spc", null, null, null, "spc1"]} py="spc" textAlign="center">
        <Box my="spc2">
          <Text textStyle="h6" caps>
            {title}
          </Text>
        </Box>
        <Text textStyle="p">{text}</Text>
      </Box>
    </Box>
  )
}

const Valores = () => {
  const listCards = data.map((card, i) => (
    <Card cardID={i + 1} title={card.title} text={card.text} />
  ))
  return (
    <MaxWidth py={["spc4", "spc4", "spc6", "spc8", "spc16"]} textAlign="center">
      <Block>
        <Text textStyle="h2">Valores</Text>
        <Tick align="center" />
        <Columns cols={[1, 3]} textAlign="left">
          {listCards}
        </Columns>
      </Block>
    </MaxWidth>
  )
}

export default Valores
