import React from "react"

import Layout from "../modules/layout"
import Nosotros from "../modules/m22_nosotros"
import Hacemos from "../modules/m23_hacemos"
import Valores from "../modules/m24_valores"
import InnerHero from "../modules/m14_InnerHero"
import LeaveData from "../modules/m21B_leaveDataContact"
import Casos from "../modules/m20_casos"
import BlockInner from "../components/blockinner"
import Card from "../components/cardCaso"
import datacasos from "../data/casos"

const SomosPage = () => {
  return (
    <Layout
      title="Quiénes Somos | Living Alternatives - ¿Cómo podemos ayudarte?"
      desc="Somos un estudio especializado en el cambio de uso de locales a viviendas reformadas y decoradas con altas rentabilidades en Madrid. Emprende con Living Alternatives."
      keywords="Living Alternatives,
      cambio de uso de local comercial a vivienda,
      cambio de uso de local a vivienda Madrid"
      url="living-alternatives-quienes-somos"
    >
      <InnerHero
        bc="Quiénes somos"
        title="Consigue altas rentabilidades con el cambio de uso de locales"
        url="living-alternatives-quienes-somos"
        tagidbotshare="TM22-QuienesSomos-MóduloCompartir-BotonCompartir"
      />
      <Nosotros />
      <Hacemos />
      <Valores />
      <LeaveData tagidbotcontact="TM23-QuienesSomos-SecciónContactar-BotonContactar" />
      <BlockInner>
        <Casos>
          <Card
            datacasos={datacasos[0]}
            tagmanagerid="TM25-QuienesSomos-MóduloCasosPracticos-JuanIziar"
            title="De un restaurante a tres viviendas"
          ></Card>
          <Card
            datacasos={datacasos[1]}
            tagmanagerid="TM26-QuienesSomos-MóduloCasosPracticos-SanVenancio"
            title="De un local en bruto a dos viviendas"
          ></Card>
          <Card
            datacasos={datacasos[2]}
            tagmanagerid="TM27-QuienesSomos-MóduloCasosPracticos-PuertoReal"
            title="De una clínica dental a dos viviendas"
          ></Card>
        </Casos>
      </BlockInner>
    </Layout>
  )
}

export default SomosPage
