import React, { useState } from "react"
import { Flex, Text } from "@sqymagma/elements"
import Tick from "../components/tick"
import Btn from "../components/button"
import ContactModal from "./m26_contactModal"
import Linkexternal from "../components/linkexternal"
import styled from "styled-components"
import data from "../data/m21B"

const CsselTlftop = styled(Linkexternal)`
  color: ${p => p.theme.color.brand01};
  font-weight: 500;
  &:hover {
    text-decoration: underline !important;
  }
`

const LeaveData = ({ tagidbotcontact, ...props }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Flex
        bg="bg01"
        height={["100vh", "50vh", "50vw", "50vw", "33vw"]}
        width="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        textAlign="center"
        {...props}
      >
        <Flex height={["64px", "64px", "105px", "105px", "135px"]} />
        <Text textStyle="h3" color="text01" width={["250px", "420px", "530px"]}>
          {data.title}
        </Text>
        <Tick align="center" />
        <Text
          textStyle="subtitle"
          color="text01"
          mb={["16px", "24px", "32px", "40px"]}
          width={["250px", "420px", "530px"]}
        >
          {data.text}
        </Text>
        <Btn
          size="regular"
          width="200px"
          height="56px"
          margin="0px auto"
          onClick={() => setOpen(true)}
          id={tagidbotcontact}
        >
          {data.cta}
        </Btn>
        {data.subtext !== "" && (
          <Text
            textStyle="p"
            color="text03"
            my={["16px", "24px", "32px", "40px"]}
            width={["250px", "420px", "530px"]}
          >
            {data.subtext1}{" "}
            <CsselTlftop href="tel:+34915613719" id="TM6-Header-Nav-Llamada" title="telefono">
              {data.number}
            </CsselTlftop>{" "}
            {data.subtext2}
          </Text>
        )}
      </Flex>
      {open && <ContactModal setClose={() => setOpen(false)} />}
    </>
  )
}

export default LeaveData
