const m24 = [
  {
    title: "profesionalidad",
    text:
      "Trabajamos desde la ética y la transparencia, con el fin de ofrecer un servicio de calidad.",
  },
  {
    title: "Confianza",
    text: "Creamos compromisos con nuestros clientes, cubriendo todas sus necesidades.",
  },
  {
    title: "seguridad",
    text:
      "Tenemos las herramientas adecuadas para encontrar oportunidades de inversión seguras y viables.",
  },
]

export default m24
